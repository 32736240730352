@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  font-family: 'Roboto', sans-serif;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #0a374c;
  /* background-color: #00000000; */
  background-image: url(/static/media/bg-card-shape-dark.030ec868.jpg);
  background-attachment: fixed;
  background-size: cover;
  /* background-position: center; */
  scroll-behavior: smooth;
}

.MuiTableCell-body {
  font-size: 0.875rem !important;
}

.rce-mbox-time {
  position: absolute !important;
  bottom: -12px !important;
}

.bg-dark {
  background-color: #0a374c;
  background-image: url(/static/media/bg-card-shape-dark.030ec868.jpg);
  background-attachment: fixed;
  background-size: cover;
  /* background-position: center; */
}

.bg {
  background-color: rgb(235, 235, 235);
  background-image: url(/static/media/bg-card-shape.3b4f42f6.jpg);
  background-attachment: fixed;
  background-size: cover;
  /* background-position: center; */
}

.MuiBreadcrumbs-ol {
  overflow: scroll;
  flex-wrap: nowrap !important;
}

.MuiSpeedDialAction-staticTooltipLabel {
  background-color: #0a374c !important;
  color: white !important;
}

.bg-primary {
  background-color: #0a374c !important;
  color: white !important;
}

.MuiFab-sizeSmall {
  background-color: #0a374c !important;
  color: white !important;
}

.MuiBadge-colorSecondary {
  color: white !important;
}

.Toastify__toast--info {
  background-color: #0a374c !important;
}

.fullscreen-enabled {
  background-color: #0a374c !important;
  height: 100vh !important;
  max-height: 100vh !important;
}

.shake {
  animation: shake 0.82s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

#base-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTabs-indicator {
  width: 100% !important;
}

